<template>
  <v-main class="natural lighten-3">
      <v-container fluid class="natural lighten-3">
        <v-row>
          <v-col cols="4"><StepCardInActive :title="'ข้อมูลส่วนตัว'"/></v-col>
          <v-col cols="4"><StepCardInActive :title="'ข้อมูลสุขภาพ'"/></v-col>
          <v-col cols="4"><StepCardActive :title="'สำเร็จ'"/></v-col>
        </v-row>
      </v-container>
      <v-container fluid class="pl-0 pr-0 natural fill-height" style="align-items: start;">
        <v-row>
<v-col cols="12">
  <v-card tile elevation="0" >
    <v-card-text class="text-center">
     <v-row>
       <v-col cols="12">
           <v-avatar size="104">
          <v-img :src="require('@/assets/Complete.svg')"/>
         
        </v-avatar>
       </v-col>
       <v-col cols="12">
          <div class="text-h5 primary--text text--darken-4">ส่งข้อมูลเรียบร้อย</div>
       </v-col>
                <v-col cols="12" class="pt-2">
                   <v-btn block large outlined color="primary" :to="{name: 'main'}"><span class="text-title-2">กลับสู่หน้าแรก</span></v-btn>
                </v-col>
                 <v-col cols="12" class="pt-0">
                   <v-btn block large color="primary text-title-2" :to="{name: 'formlist'}">กลับสู่เมนูคัดกรอง</v-btn>
                </v-col>
              </v-row>
    </v-card-text>
  </v-card>
</v-col>
       
        </v-row>
      </v-container>
     
  </v-main>
</template>

<script>
import StepCardActive from "@/components/form/StepCardActive";
import StepCardInActive from "@/components/form/StepCardInactive";
// import TextField from "@/components/form/TextField";
// import TextArea from "@/components/form/TextArea";
import {mask} from 'vue-the-mask'
export default {
components: {
  StepCardActive,
  StepCardInActive,
  // TextField,
  // TextArea,
},
directives: {mask},
data() {
  return {
    cid: null
  }
},
}
</script>

<style lang="scss" scoped>
    .v-btn {
    border-radius: 6px;
    }


</style>